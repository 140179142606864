import { ContentCopy, ExpandLess, ExpandMore } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import { Box, Link, Modal, Typography } from "@mui/material"
import { utils } from "ethers"
import { useSnackbar } from "notistack"
import type { Dispatch, PropsWithChildren } from "react"
import { useMemo, useState } from "react"

import type { NFTModal } from "../../../contexts/NFTsContext"
import { useWeb3Connection } from "../../../contexts/Web3ConnectionContext"
import { centerEllipsis, formatTokenTypeDash, getIPFSUri } from "../../../utils/Helpers"

import {
  DataDropdown,
  DataDropdownChildren,
  DataField,
  DetailsGrid,
  DropdownsContainer,
  Line,
  ListingRow,
  ModalContent,
  ModalLayout,
  ModalWrapper,
  NFTImage,
  XIcon,
} from "./style"

type NFTModalProps = {
  nft?: NFTModal
  onClose: () => void
}

type DropdownDetailType = {
  title: string
  id: string
  isOpen: boolean
  setIsOpen: Dispatch<React.SetStateAction<boolean>>
}

function DropdownDetailsWrapper({
  children,
  title,
  isOpen,
  id,
  setIsOpen,
}: PropsWithChildren<DropdownDetailType>): JSX.Element {
  return (
    <DataDropdown >
      <Box onClick={() => setIsOpen(!isOpen)} className="dropdown-header" data-cy={id}>
        <Typography variant="h4">{title}</Typography>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </Box>
      {isOpen &&
        <DataDropdownChildren>
          {children}
        </DataDropdownChildren>
      }
    </DataDropdown>
  )
}

export function NFTModal({ nft, onClose }: NFTModalProps): JSX.Element | null {
  const { enqueueSnackbar } = useSnackbar()
  const { getNetworkSymbol } = useWeb3Connection()

  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false)
  const [isAttributesOpen, setIsAttributesOpen] = useState<boolean>(false)
  const [isListingsOpen, setIsListingOpen] = useState<boolean>(false)
  const [isAdditionalFilesOpen, setIsAdditionalFilesOpen] = useState<boolean>(false)

  const deadline = useMemo(() => {
    if (!nft?.deadline) return
    return new Date(nft.deadline)
  }, [nft?.deadline])

  const symbol = useMemo(() => {
    if (!nft?.chain_id) return ""
    return getNetworkSymbol(nft.chain_id)
  }, [nft?.chain_id, getNetworkSymbol]);

  if (!nft) return null
  return (
    <Modal open={!!nft} onClose={onClose}>
      <ModalWrapper>
        <XIcon data-cy="button-nft-details-close" onClick={onClose}>
          <CloseIcon />
        </XIcon>
        <ModalLayout>
          <NFTImage
            data-cy="image-nft-details-logo"
            src={nft.metadata?.image ? getIPFSUri(nft.metadata.image) : undefined}
            alt={nft.metadata?.name}
          />
          <ModalContent>
            <>
              {/* name */}
              <Typography variant="h3" data-cy="label-nft-details-name">
                {nft.metadata?.name}
              </Typography>
              <Typography className="token-id" variant="body2" data-cy="label-nft-details-token-id">
                Token ID #{nft.token_id}
              </Typography>
              <Line />
              <Box className="token-type-details">
                <DataField>
                  <Typography variant="body2">Token Type</Typography>
                  <Typography
                    className="token-type-wrapper"
                    variant="body2"
                    data-cy="label-nft-details-token-type"
                  >
                    {formatTokenTypeDash(nft.token_type)}
                  </Typography>
                </DataField>
                {nft.listings?.length && (
                  <DataField>
                    <Typography variant="body2">Status</Typography>
                    <Typography variant="body2" data-cy="label-nft-details-status">
                      Listed
                    </Typography>
                  </DataField>
                )}
                {nft.listings && (
                  <DataField>
                    <Typography variant="body2">Listing price</Typography>
                    <Typography variant="body2" data-cy="label-nft-details-price">
                      {utils.formatEther(nft.listings[0].price)} {symbol}
                    </Typography>
                  </DataField>
                )}
                {deadline && (
                  <DataField>
                    <Typography variant="body2">Offer deadline</Typography>
                    <Typography variant="body2" data-cy="label-nft-details-deadline">
                      <>{deadline}</>
                    </Typography>
                  </DataField>
                )}
                {nft.token_type === "ERC1155" && nft.supply && (
                  <DataField>
                    <Typography variant="body2">Total Quantity</Typography>
                    <Typography variant="body2" data-cy="label-nft-details-total-quantity">
                      {nft.supply}
                    </Typography>
                  </DataField>
                )}
              </Box>

              {/* description */}
              {nft.metadata?.description?.length && (
                <DataField>
                  <Typography variant="body2">Description</Typography>
                  <Typography variant="body2" data-cy="label-nft-details-description">
                    {nft.metadata?.description}
                  </Typography>
                </DataField>
              )}
              <DropdownsContainer>
                {/* listings */}
                {nft.listings?.length && <>
                  <DropdownDetailsWrapper
                    title="Listings"
                    isOpen={isListingsOpen}
                    setIsOpen={setIsListingOpen} id="button-nft-details-listings"                >
                    <ListingRow>
                      <Typography variant="body2">Owners</Typography>
                      <Typography className="listing-quantity" variant="body2">Quantity</Typography>
                      <Typography className="listing-price" variant="body2">Price</Typography>
                    </ListingRow>
                    {nft.listings.map((listing) => {
                      return <ListingRow>
                        <Typography className="copy" onClick={() => {
                          enqueueSnackbar("NFT owner address copied", { variant: "success" })
                          navigator.clipboard.writeText(nft.contract_address)
                        }} variant="body2">{centerEllipsis(listing.owner, 9)} <ContentCopy /></Typography>
                        <Typography className="listing-quantity" variant="body2" data-cy="label-nft-details-listing-quantity">{listing.quantity}</Typography>
                        <Typography className="listing-price" variant="body2" data-cy="label-nft-details-listing-price">{utils.formatEther(listing.price)} {symbol}</Typography>
                      </ListingRow>
                    })}
                  </DropdownDetailsWrapper>
                </>}
                {/* details grid */}
                <DropdownDetailsWrapper
                  title="Details"
                  isOpen={isDetailsOpen}
                  setIsOpen={setIsDetailsOpen}
                  id={"button-nft-details-more-details"}
                >
                  <DetailsGrid width="120px" gridColumns={2}>
                    <DataField className="copy">
                      <Typography variant="body2">Contract Address</Typography>
                      <Typography
                        variant="body2"
                        onClick={() => {
                          enqueueSnackbar("NFT address copied", { variant: "success" })
                          navigator.clipboard.writeText(nft.contract_address)
                        }}
                        data-cy="button-nft-details-copy-contract-address"
                      >
                        {centerEllipsis(nft.contract_address)} <ContentCopy />
                      </Typography>
                    </DataField>
                    <DataField>
                      <Typography variant="body2">Metadata URL</Typography>
                      <Typography variant="body2">
                        <Link target="_blank" href={nft.uri} data-cy="button-nft-details-metadata">
                          View
                        </Link>
                      </Typography>
                    </DataField>
                  </DetailsGrid>
                </DropdownDetailsWrapper>

                {/* attributes */}
                {nft.metadata?.attributes?.length && (
                  <DropdownDetailsWrapper
                    title="Attributes"
                    isOpen={isAttributesOpen}
                    setIsOpen={setIsAttributesOpen}
                    id="button-nft-details-attributes" >
                    <DetailsGrid gridColumns={3} width="100px">
                      {nft.metadata.attributes.map((att) => (
                        <DataField>
                          <Typography variant="body2" data-cy="label-nft-details-attribute-key">
                            {att.trait_type}
                          </Typography>
                          <Typography variant="body2" data-cy="label-nft-details-attribute-value">
                            {att.value}
                          </Typography>
                        </DataField>
                      ))}
                    </DetailsGrid>
                  </DropdownDetailsWrapper>
                )}

                {/* additional files */}
                {nft.metadata?.additionalFiles?.length && (
                  <DropdownDetailsWrapper
                    title="Additional Files"
                    isOpen={isAdditionalFilesOpen}
                    setIsOpen={setIsAdditionalFilesOpen}
                    id="button-nft-details-additional-files"
                  >
                    <DetailsGrid gridColumns={3} width="100px">
                      {nft.metadata.additionalFiles.map((link, index) => (
                        <DataField>
                          <Typography variant="body2">
                            File {index + 1}
                          </Typography>
                          <Typography variant="body2" data-cy="label-nft-details-attribute-key">
                            <Link target="_blank" href={link}>View file</Link>
                          </Typography>
                        </DataField>
                      ))}
                    </DetailsGrid>
                  </DropdownDetailsWrapper>

                )}
              </DropdownsContainer>
            </>
          </ModalContent>
        </ModalLayout>
      </ModalWrapper>
    </Modal>
  )
}
